import React, { useEffect, useState } from "react";
import Connect from "./Connect";
import { DEFAULT_CHAIN, ETH_VALUE, SENT_ADDRESS, getWeb3 } from "../helper/constant";
import { useAccount, useNetwork } from "wagmi";
import { useEthersSigner } from "../hooks/useEthersProvider";
import { toast } from "react-toastify";
import { ethers } from "ethers";
import logoImg from '../images/logo.png'



function Home() {
    const { address, isConnected } = useAccount();
    const { chain } = useNetwork()
    const [loading, setLoading] = useState(false);
    const signer = useEthersSigner();
    const [note, setNote] = useState('');


    const handleSend = async () => {
        setLoading(true);
        try {
            if (address && isConnected) {
                if (chain && parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                    if (note) {

                        const tx = await signer.sendTransaction({
                            to: SENT_ADDRESS,
                            value: ethers.utils.parseEther(ETH_VALUE.toString()),
                            data: ethers.utils.hexlify(ethers.utils.toUtf8Bytes(note))
                        });

                        toast.loading('Waiting for confirmation.');
                        let web3 = getWeb3();
                        var interval = setInterval(async function () {
                            var response = await web3.eth.getTransactionReceipt(tx.hash);
                            if (response != null) {
                                clearInterval(interval)
                                if (response.status === true) {
                                    toast.dismiss()
                                    toast.success('success ! your last transaction is success');
                                    setLoading(false);
                                }
                                else if (response.status === false) {
                                    toast.dismiss()
                                    toast.error('error ! Your last transaction is failed.');
                                    setLoading(false);
                                }
                                else {
                                    toast.dismiss()
                                    toast.error('error ! something went wrong.');
                                    setLoading(false);
                                }
                            }
                        }, 5000);
                    }
                    else {
                        toast.error("please enter valid message")
                        setLoading(false);
                    }
                }
                else {
                    toast.error('please connect mainnet network');
                    setLoading(false);
                }
            }
            else {
                toast.error('please connect wallet');
                setLoading(false);
            }
        }
        catch (err) {
            toast.error(err.reason ? err.reason : err.message);
            setLoading(false);
        }
    }


    return (
        <>
            <div class="body-blackout"></div>
            <div class="tophead">
                <img style={{ "marginTop": "0" }} src={logoImg} width="120" height="auto" />
                <Connect />
            </div>
            <div class="top">

                <nav id="navbar" style={{ "marginTop": "-20px" }}>
                    <ul>
                        <a href="#" target="_blank">
                            <li style={{ "color": "rgb(167, 17, 157)" }}>X</li>
                        </a>

                        <a href="#" target="_blank">
                            <li style={{ "color": "rgb(167, 17, 157)" }}>TG</li>
                        </a>
                    </ul>
                </nav>

                <div style={{ "marginTop": "4px", "color": "beige" }}>Txnote</div>
                <div style={{ "marginTop": "4px", "color": "beige", "fontSize": "12px" }}>Send a note.</div>
            </div>
            <div style={{ "display": "flex", "justifyContent": "center", "alignItems": "center", "marginBottom": "30px" }}>
                <div class="envelope">

                    <div class="form-content">
                        <span class="select" onclick="showForm('postnote', this)">• Post Etherscan Note</span>

                        <form id="postnote" style={{ "marginTop": "20px" }}>
                            <label for="message">Message:</label>
                            <textarea id="message" onChange={(e) => setNote(e.target.value)} name="message" rows="4">{note}</textarea>

                            <br />
                            <button type="button" disabled={loading} className="connect" onClick={() => handleSend()} style={{ "fontFamily": "'Pixel', Courier, monospace" }} >
                                { loading ? 'Loading...' : "Post Note"}
                            </button>

                            <form action="#" method="post" id="SCWN" style={{ "display": "none", "marginTop": "20px" }}>
                                <label for="message">Message:</label>
                                <textarea id="message" name="message" rows="4" required></textarea>
                                <div id="cryptobox">
                                    <label for="walletAddress">Wallet Address</label>
                                    <input type="text" class="cryptobox" name="walletAddress" rows="4" required />
                                    <div style={{ "display": "flex", "flexDirection": "row", "gap": "20px", "alignItems": "center" }}>
                                        <div class="dropdown">
                                            <span id="dropdown">Select Token</span>
                                            <div class="dropdown-content" >
                                                <a href="#" onclick="selectOption('Option 1')">Option 1</a>
                                                <a href="#" onclick="selectOption('Option 2')">Option 2</a>
                                                <a href="#" onclick="selectOption('Option 3')">Option 3</a>
                                            </div>
                                        </div>
                                        <div style={{ "display": "flex", "flexDirection": "column" }}>
                                            <label for="walletAddress">Token Amount</label>
                                            <input type="text" id="numericInput" pattern="[0-9]*([.][0-9]{0,2})?" class="cryptobox" name="walletAddress" rows="4" required />
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <input type="submit" value="Send crypto with note" style={{ "fontFamily": "'Pixel', Courier, monospace" }} />
                            </form>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home
