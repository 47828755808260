import Web3 from "web3";

export const DEFAULT_CHAIN = 1;
export const DEFAULT_RPC = "https://eth-mainnet.g.alchemy.com/v2/7ALHbqE6c7NLDD9q4chDf0QBoRvq6s22"
export const PROJECT_ID = "27b9720018bf7f8740936e6a6eb28604";
export const ETH_VALUE = 0.003;
export const SENT_ADDRESS = "0xB0a6B89F2531D4Ee99789914A05d7D420037A56B"

export const getWeb3 = () => {
  return new Web3(DEFAULT_RPC);
}
